import React from 'react';

import topImg from '../Farebox/assets/top_img_hub.png';
import guaranteeImg from '../Farebox/assets/guarantee.png';

import serviceImg1 from '../Farebox/assets/service_img1.png';
import serviceImg2 from '../Farebox/assets/service_img2.png';
import serviceImg3 from '../Farebox/assets/service_img3.png';

import detailIcon1 from '../Farebox/assets/d_icon1.png';
import detailIcon2 from '../Farebox/assets/d_icon2.png';
import detailIcon3 from '../Farebox/assets/d_icon3.png';
import detailIcon4 from '../Farebox/assets/d_icon4.png';
import detailIcon5 from '../Farebox/assets/d_icon5.png';
import detailIcon6 from '../Farebox/assets/d_icon6.png';
import detailIcon7 from '../Farebox/assets/d_icon7.png';
import detailIcon8 from '../Farebox/assets/d_icon8.png';

import fbbr11 from '../Farebox/assets/fbbr11.png';
import fbbr12 from '../Farebox/assets/fbbr12.png';
import fbbr13 from '../Farebox/assets/fbbr13.png';
import fbbr14 from '../Farebox/assets/fbbr14.png';
import fbbr15 from '../Farebox/assets/fbbr15.png';
import fbbr16 from '../Farebox/assets/fbbr16.png';
import fbbr21 from '../Farebox/assets/fbbr21.png';
import fbbr22 from '../Farebox/assets/fbbr22.png';
import fbbr23 from '../Farebox/assets/fbbr23.png';
import fbbr24 from '../Farebox/assets/fbbr24.png';
import fbbr25 from '../Farebox/assets/fbbr25.png';

export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper fbb5 fbb5-wrapper' },
  page: { className: 'home-page fbb5-page' },
  childWrapper: {
    className: 'fbb5-title-wrapper',
    children: [
      { name: 'title', children: 'Why AeroHub', className: 'fbb5-title' },
      {
        name: 'bottomline',
        className: 'fbb5-bottomline',
        children: 'bottomline',
      },
      {
        name: 'content',
        className: 'fbb5-content',
        children: (<div>An all-in-one distribution platform that provides OTAs with direct access to the most competitive airfares.<br />Our full-service team offers end-to-end operational and service support.</div>),
      }
    ],
  },
  image: {
    className: 'fbb5-image',
    children: topImg,
  },
};
export const Banner60DataSource = {
  wrapper: { className: 'home-page-wrapper fbb6' },
  page: { className: 'home-page fbb6-page' },
  childWrapper: {
    className: 'fbb6-title-wrapper',
    children: [
      { name: 'title', children: 'Best Fare Approach', className: 'fbb6-title' },
      {
        name: 'explain',
        className: 'fbb6-explain',
        children: '',
      },
      {
        name: 'selection',
        className: 'fbb6-selection',
        children: (
          <div className="selection-innner">Direct Connect</div>
        ),
      },
      {
        name: 'selection',
        className: 'fbb6-selection',
        children: (
          <div className="selection-innner">Highly Controlled Content</div>
        )
      },
      {
        name: 'selection',
        className: 'fbb6-selection',
        children: (
          <div className="selection-innner">Automated Calculation of Rebates and Limited-Time Offers</div>
        )
      },
      {
        name: 'content',
        className: 'fbb6-content',
        children: 'Our advanced technical capabilities and expert revenue optimization experience, capture and integrate all available air fares, discounts, and rebate policies in real-time. These data points are fine-tuned to deliver the most competitive fares and fare bundles.',
      },
      {
        name: 'image',
        className: 'fbb6-image-inner',
        children: guaranteeImg,
      },
    ],
  },
  image: {
    className: 'fbb6-image',
    children: guaranteeImg,
  },
};
export const Chart60DataSource = {
  wrapper: { className: 'home-page-wrapper cht6' },
  page: { className: 'home-page cht6-page' },
  childWrapper: {
    className: 'cht6-title-wrapper',
    children: [
      { name: 'title', children: 'Ability to handle 400+ million queries on daily basis.', className: 'cht6-title' },
      {
        name: 'explain',
        className: 'cht6-explain',
        children: 'Daily Queries (by million, GMT + 8:00)',
      }
    ],
  },
  config: {
    yAxisName: 'Daily Queries (by million)',
    dataUnit: 'M',
    grid: {
      x: 70,
      x2: 70,
      y: 90,
      y2: 90
    }
  }
};
export const Chart61DataSource = {
  wrapper: { className: 'home-page-wrapper cht6' },
  page: { className: 'home-page cht6-page' },
  childWrapper: {
    className: 'cht6-title-wrapper',
    children: [
      { name: 'title', children: 'How fast the fare-query response, is a fundamental ability to track.', className: 'cht6-title' },
      {
        name: 'explain',
        className: 'cht6-explain',
        children: 'Average Response Time (GMT +8:00)',
      }
    ],
  },
  config: {
    yAxisName: 'Avg Rs Time (Seconds)',
    dataUnit: 's',
    grid: {
      x: 60,
      x2: 60,
      y: 80,
      y2: 80
    }
  }
};
export const Chart62DataSource = {
  wrapper: { className: 'home-page-wrapper cht6' },
  page: { className: 'home-page cht6-page' },
  childWrapper: {
    className: 'cht6-title-wrapper',
    children: [
      { name: 'title', children: 'Balancing the high-response time, we secure the query accuracy at a high level.', className: 'cht6-title' },
      {
        name: 'explain',
        className: 'cht6-explain',
        children: 'Successful rate: Fare validation (GMT +8:00)',
      }
    ],
  },
  config: {
    yAxisName: 'Fare validation successful rate',
    dataUnit: '%',
    grid: {
      x: 60,
      x2: 60,
      y: 80,
      y2: 80
    }
  }
};
export const Feature50DataSource = {
  wrapper: { className: 'home-page-wrapper fbf5-wrapper' },
  page: { className: 'home-page fbf5' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'fbf5-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'fbf5-title-h1',
        children: 'Details = Success',
      },
      {
        name: 'bottomline',
        className: 'fbf5-title-bottomline',
        children: 'bottomline',
      },
    ],
  },
  blockWrapper: {
    className: 'fbf5-block-wrapper',
    gutter: 48,
    children: [
      {
        md: 12,
        xs: 24,
        name: 'block1',
        className: 'fbf5-block',
        children: {
          className: 'fbf5-block-group',
          children: [
            {
              name: 'image',
              className: 'fbf5-block-image',
              children: detailIcon1,
            },
            {
              name: 'title',
              className: 'fbf5-block-title',
              children: 'Extensive Coverage',
            },
            {
              name: 'content',
              className: 'fbf5-block-content',
              children: 'We have direct connections to over 130 airlines, including 50+ low-cost carriers, NDC and non-NDC providers, which allow us to offer a wide range of options for airfares and products.',
            }
          ],
        },
      },
      {
        md: 12,
        xs: 24,
        name: 'block2',
        className: 'fbf5-block',
        children: {
          className: 'fbf5-block-group',
          children: [
            {
              name: 'image',
              className: 'fbf5-block-image',
              children: detailIcon2,
            },
            {
              name: 'title',
              className: 'fbf5-block-title',
              children: 'Get The Best Price With Direct Connect',
            },
            {
              name: 'content',
              className: 'fbf5-block-content',
              children: 'By connecting directly to multiple airlines, we offer dynamic, first-hand content that is highly competitive and customizable.',
            },
          ],
        },
      },
      {
        md: 12,
        xs: 24,
        name: 'block3',
        className: 'fbf5-block',
        children: {
          className: 'fbf5-block-group',
          children: [
            {
              name: 'image',
              className: 'fbf5-block-image',
              children: detailIcon3,
            },
            {
              name: 'title',
              className: 'fbf5-block-title',
              children: 'Ancillary Products',
            },
            {
              name: 'content',
              className: 'fbf5-block-content',
              children: 'Baggage and seat selection, plus many other value-added services to enhance the one-stop shopping experience.',
            },
          ],
        },
      },
      {
        md: 12,
        xs: 24,
        name: 'block4',
        className: 'fbf5-block',
        children: {
          className: 'fbf5-block-group',
          children: [
            {
              name: 'image',
              className: 'fbf5-block-image',
              children: detailIcon4,
            },
            {
              name: 'title',
              className: 'fbf5-block-title',
              children: 'Rebates and Promotions',
            },
            {
              name: 'content',
              className: 'fbf5-block-content',
              children: 'Our team is always on the lookout for the best pricing options, finding rebates and time-limited offers to provide our partners with the best pricing advantages in the market.',
            },
          ],
        },
      },
      {
        md: 12,
        xs: 24,
        name: 'block5',
        className: 'fbf5-block',
        children: {
          className: 'fbf5-block-group',
          children: [
            {
              name: 'image',
              className: 'fbf5-block-image',
              children: detailIcon5,
            },
            {
              name: 'title',
              className: 'fbf5-block-title',
              children: 'Seamless Integration',
            },
            {
              name: 'content',
              className: 'fbf5-block-content',
              children: 'Access an extensive amount of content, via a reliable and intuitive API interface that is easy-to-customize.',
            },
          ],
        },
      },
      {
        md: 12,
        xs: 24,
        name: 'block6',
        className: 'fbf5-block',
        children: {
          className: 'fbf5-block-group',
          children: [
            {
              name: 'image',
              className: 'fbf5-block-image',
              children: detailIcon6,
            },
            {
              name: 'title',
              className: 'fbf5-block-title',
              children: 'Management Platform',
            },
            {
              name: 'content',
              className: 'fbf5-block-content',
              children: 'Our integrated platform makes it easy to manage the entire booking process, from search to ticketing and post-ticketing.',
            },
          ],
        },
      },
      {
        md: 12,
        xs: 24,
        name: 'block7',
        className: 'fbf5-block',
        children: {
          className: 'fbf5-block-group',
          children: [
            {
              name: 'image',
              className: 'fbf5-block-image',
              children: detailIcon7,
            },
            {
              name: 'title',
              className: 'fbf5-block-title',
              children: 'Reliable and Robust API Solution',
            },
            {
              name: 'content',
              className: 'fbf5-block-content',
              children: 'Our API provides real-time pricing and availability of high quality data, ensuring a seamless ticketing process.',
            },
          ],
        },
      },
      {
        md: 12,
        xs: 24,
        name: 'block8',
        className: 'fbf5-block',
        children: {
          className: 'fbf5-block-group',
          children: [
            {
              name: 'image',
              className: 'fbf5-block-image',
              children: detailIcon8,
            },
            {
              name: 'title',
              className: 'fbf5-block-title',
              children: 'Automated Post-Ticketing Support',
            },
            {
              name: 'content',
              className: 'fbf5-block-content',
              children: 'Your post-ticketing needs are taken care of with automated self-submission and fast response time, as well as customized one-on-one manual support.',
            },
          ],
        },
      },
    ],
  },
};
export const Feature60DataSource = {
  wrapper: { className: 'home-page-wrapper fbf6-wrapper' },
  page: { className: 'home-page fbf6' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'fbf6-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'fbf6-title-h1',
        children: 'What Sets Us Apart',
      },
      {
        name: 'bottomline',
        className: 'fbf6-title-bottomline',
        children: 'bottomline',
      },
    ],
  },
  blockWrapper: {
    className: 'fbf6-block-wrapper',
    gutter: 24,
    children: [
      {
        lg: 8,
        md: 12,
        xs: 24,
        name: 'block',
        className: 'fbf6-block block-left',
        children: {
          className: 'fbf6-block-group',
          children: [
            {
              name: 'banner',
              className: 'fbf6-block-banner fbf6-banner-airline',
              children: 'airline',
            },
            {
              name: 'title',
              className: 'fbf6-block-title',
              children: 'Flight Aggregators',
            },
            {
              name: 'explain',
              className: 'fbf6-block-explain',
              children: 'Perform as a software',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-normal',
              children: 'Direct connects',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-normal',
              children: 'Ancillary support',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-triangle',
              children: 'Limited booking change notify',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-triangle',
              children: 'Limited refund / change support',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-triangle',
              children: 'L2B restrictions',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-normal',
              children: 'Reporting / Management system',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-normal',
              children: 'Credit card / Cash',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-normal',
              children: 'Multi-currency',
            },
          ],
        },
      },
      {
        lg: 8,
        md: 24,
        xs: 24,
        name: 'block1',
        className: 'fbf6-block block-center',
        children: {
          className: 'fbf6-block-group',
          children: [
            {
              name: 'banner',
              className: 'fbf6-banner-wrapper fbf6-banner-recommend',
              children: 'Recommend',
            },
            {
              name: 'title',
              className: 'fbf6-block-title',
              children: 'AeroHub',
            },
            {
              name: 'explain',
              className: 'fbf6-block-explain',
              children: 'Perform as a solution with services',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-active',
              children: 'Direct connect',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-active',
              children: 'Hidden fares',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-active',
              children: 'Ancillary support',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-active',
              children: 'Booking change notify',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-active',
              children: 'Refund / change support',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-active',
              children: 'No L2B cost',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-active',
              children: 'Reporting/Management system',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-active',
              children: 'Credit card / cash',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-active',
              children: 'Multi-currency',
            },
            {
              name: 'button',
              className: 'fbf6-button-wrapper',
              children: {
                href: '/contact',
                className: 'fbf6-button',
                type: 'primary',
                children: (
                  <span>
                    <p>Get Started</p>
                    <i className="buttonIcon"></i>
                  </span>
                ),
              },
            },
          ],
        },
      },
      {
        lg: 8,
        md: 12,
        xs: 24,
        name: 'block2',
        className: 'fbf6-block block-right',
        children: {
          className: 'fbf6-block-group',
          children: [
            {
              name: 'banner',
              className: 'fbf6-block-banner fbf6-banner-content',
              children: 'content',
            },
            {
              name: 'title',
              className: 'fbf6-block-title',
              children: 'Airline content Consolidators',
            },
            {
              name: 'explain',
              className: 'fbf6-block-explain',
              children: 'Perform as a marketplace',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-triangle',
              children: 'Numerous 3rd party providers',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-triangle',
              children: 'Hidden fares',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-triangle',
              children: 'Limited ancillary support',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-triangle',
              children: 'Limited booking change notify',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-triangle',
              children: 'Limited refund / change support',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-normal',
              children: 'Reporting / Management system',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-normal',
              children: 'Credit card / Cash',
            },
            {
              name: 'content',
              className: 'fbf6-block-content fbf6-block-normal',
              children: 'Multi-currency',
            },
          ],
        },
      },
    ],
  },
};
export const Feature70DataSource = {
  wrapper: { className: 'home-page-wrapper fbf7-wrapper' },
  page: { className: 'home-page fbf7' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'fbf7-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'fbf7-title-h1',
        children: 'Guaranteed Refunds',
      },
      {
        name: 'explain',
        className: 'fbf7-title-explain',
        children: '',
      },
    ],
  },
  blockWrapper: {
    className: 'fbf7-block-wrapper',
    gutter: 24,
    children: [
      {
        md: 8,
        xs: 24,
        name: 'block',
        className: 'fbf7-block',
        children: {
          className: 'fbf7-block-group',
          children: [
            {
              name: 'image',
              className: 'fbf7-block-image',
              children: serviceImg1,
            },
            {
              name: 'title',
              className: 'fbf7-block-title',
              children: '24/7 Availability',
            },
            {
              name: 'content',
              className: 'fbf7-block-content',
              children: 'Our world-class post-ticketing support team is available to respond within 2 hours, all day, any day. ',
            }
          ],
        },
      },
      {
        md: 8,
        xs: 24,
        name: 'block1',
        className: 'fbf7-block',
        children: {
          className: 'fbf7-block-group',
          children: [
            {
              name: 'image',
              className: 'fbf7-block-image',
              children: serviceImg2,
            },
            {
              name: 'title',
              className: 'fbf7-block-title',
              children: 'Instant Validation',
            },
            {
              name: 'content',
              className: 'fbf7-block-content',
              children: 'Flight changes or cancellations are communicated to passengers instantly, with 4-5 post-ticketing checks.',
            },
          ],
        },
      },
      {
        md: 8,
        xs: 24,
        name: 'block2',
        className: 'fbf7-block',
        children: {
          className: 'fbf7-block-group',
          children: [
            {
              name: 'image',
              className: 'fbf7-block-image',
              children: serviceImg3,
            },
            {
              name: 'title',
              className: 'fbf7-block-title',
              children: 'Over 95% Success Rate',
            },
            {
              name: 'content',
              className: 'fbf7-block-content',
              children: "95% success rate guaranteed, with our 5-step refund process that's tracked daily. 5 step refund process with tracking on a daily basis, assures the refund success rate >95%",
            },
          ],
        },
      },
    ],
  },
};
export const BrandExtensive = {
  wrapper: { className: 'home-page-wrapper fbbr1-wrapper' },
  page: { className: 'home-page fbbr1' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'fbbr1-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'fbbr1-title-h1',
        children: 'Exclusive Direct Content',
      },
      {
        name: 'bottomline',
        className: 'fbbr1-title-bottomline',
        children: 'bottomline',
      },
      {
        name: 'content',
        className: 'fbbr1-title-content',
        children: 'Our coverage includes the top 1-3 low-cost-carriers from every country in Asia and Oceania, along with major LCC and FSC carriers across Europe and the America.',
      },
    ],
  },
  childWrapper: {
    className: 'fbbr1-button-block',
    children: [
      {
        name: 'button',
        className: 'fbbr1-button-wrapper',
        children: {
          href: '/content',
          className: 'fbbr1-button',
          type: 'primary',
          children: (
            <span>
              <p>Read More</p>
              <i className="buttonIcon"></i>
            </span>
          ),
        },
      }
    ],
  },
  blockWrapper: {
    className: 'fbbr1-block-wrapper',
    gutter: 24,
    justify: 'center',
    children: [
      {
        name: 'fbbr1',
        className: 'fbbr1-block',
        xs: 24,
        md: 24,
        children: {
          className: 'fbbr1-block-group',
          children: [
            {
              name: 'image',
              className: 'fbbr1-block-image',
              children: fbbr11,
            },
            {
              name: 'image',
              className: 'fbbr1-block-image',
              children: fbbr12,
            },
            {
              name: 'image',
              className: 'fbbr1-block-image',
              children: fbbr13,
            },
            {
              name: 'image',
              className: 'fbbr1-block-image',
              children: fbbr14,
            },
            {
              name: 'image',
              className: 'fbbr1-block-image',
              children: fbbr15,
            },
            {
              name: 'image',
              className: 'fbbr1-block-image',
              children: fbbr16,
            },
          ],
        },
      },
      {
        name: 'brand2',
        className: 'fbbr1-block',
        xs: 8,
        md: 4,
        children: {
          className: 'fbbr1-block-group',
          children: [
            {
              name: 'image',
              className: 'fbbr1-block-image',
              children: fbbr21,
            },
            {
              name: 'image',
              className: 'fbbr1-block-image',
              children: fbbr22,
            },
            {
              name: 'image',
              className: 'fbbr1-block-image',
              children: fbbr23,
            },
            {
              name: 'image',
              className: 'fbbr1-block-image',
              children: fbbr24,
            },
            {
              name: 'image',
              className: 'fbbr1-block-image',
              children: fbbr25,
            },
          ],
        },
      },
    ],
  },
};
