/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import './less/antMotionStyle.less';
import { Col, Row, Anchor, Typography } from 'antd';
const { Link } = Anchor;
const { Title } = Typography;

export default class privacyPolicy extends React.Component {
  render() {
    return (
      <div
        className="templates-wrappe1r"
        ref={(d) => {
          this.dom = d;
        }}
      >
        <div className="contact-wrapper" key="Contact_content">
          <div className="contact-content" style={{ maxWidth: '1280px' }}>
            <div className='general-conditions'>
              <Row>
                <Col span={8}>
                  <Anchor>
                    <Title level={3} style={{ marginTop: '30px', paddingLeft: '16px' }}>General Conditions</Title>
                    <Link href="#policy-1" title="Introduction" />
                    <Link href="#policy-2" title="1.Objective of the General Conditions " />
                    <Link href="#policy-3" title="2.Definitions" />
                    <Link href="#policy-4" title="3.Acceptance of the General Conditions - modification" />
                    <Link href="#policy-5" title="4.Access and availability of the Platform" />
                    <Link href="#policy-6" title="5.Access to Services" />
                    <Link href="#policy-7" title="6.User’s guarantees" />
                    <Link href="#policy-8" title="7.Company's guarantee" />
                    <Link href="#policy-9" title="8.Limitation of liability" />
                    <Link href="#policy-10" title="9.Force majeure" />
                    <Link href="#policy-11" title="10.Intellectual Property" />
                    <Link href="#policy-12" title="11.Personal data" />
                    <Link href="#policy-13" title="12.Hypertext links" />
                    <Link href="#policy-14" title="13.Termination - deletion" />
                    <Link href="#policy-15" title="14.Consequences of deleting or suspending the User Account" />
                    <Link href="#policy-16" title="15.Divisibility" />
                    <Link href="#policy-17" title="16.Claim" />
                    <Link href="#policy-18" title="17.Title of the conditions" />
                    <Link href="#policy-19" title="18.Applicable law and jurisdiction" />
                    <Link href="#policy-20" title="19.Changes/Cancellations by the Suppliers " />
                    <Link href="#policy-21" title="20.Balance currency " />
                    <Link href="#policy-22" title="21.Refunds" />
                    <Link href="#policy-23" title="22.Non-refundable policy" />
                  </Anchor>
                </Col>
                <Col span={16} style={{ fontSize: '17px', lineHeight: '30px', color: '#000', marginBottom: '300px' }}>
                  <div id="policy-1">
                    <Title level={2} style={{ marginTop: '30px', textAlign: 'center' }}>General Conditions of Service and Use of the Site</Title>
                    <Title level={2} style={{ marginTop: '0px', textAlign: 'center' }}>&lt;www.aerohubtech.com&gt;</Title>
                    <Title level={3}>Introduction</Title>
                    <p>The website www.aerohubtech.com is owned by the company LETSFLY LIMITED, Flat/RM 27, 10/F Cheung Tak Industrial Building, NO.30 Wong Chuk Hang Road, Wong Chuk Hang, Hong Kong, registered under the number 2300539 (referred to hereinafter as “<b>AEROHUB</b>”).</p>
                  </div>
                  <div id="policy-2">
                    <Title level={3}>1.Objective of the General Conditions</Title>
                    <p>The purpose of these General Conditions of Service and Use ("General Conditions") is to define the terms and conditions for making the Platform available, accessible at &lt;www.aerohubtech.com&gt;, allowing AEROHUB to distribute its offer to its clients and prospects.</p>
                    <p>These General Conditions apply to all access, consultation and use of the Platform by a User. </p>
                  </div>
                  <div id="policy-3">
                    <Title level={3}>2.Definitions</Title>
                    <p>The terms or expressions, used with a first capital letter, singular or plural, have the following definition in the present General Conditions: </p>
                    <p><b>"Company"</b>: means LETSFLY LIMITED, publisher and owner of the Platform.</p>
                    <p><b>"OTA"</b>: means online travel agencies which provide travel and flight reservation services to passengers.</p>
                    <p><b>"Platform"</b>: refers to the www.aerohubtech.com platform published by AEROHUB.</p>
                    <p><b>"Service"</b>: refers to the functions offered within the Platform.</p>
                    <p><b>"User"</b>: means any natural person or legal person, whether or not a holder of a User Account, accessing the Platform.</p>
                    <p><b>"User Account"</b>: means the account owned by a User on the Platform in order to benefit from the Services of AEROHUB. </p>
                  </div>
                  <div id="policy-4">
                    <Title level={3}>3.Acceptance of the General Conditions - modification</Title>
                    <p>The User undertakes to read the entirety of these General Conditions on each visit to the Platform.</p>
                    <p>The User acknowledges that he/she has read and understood these General Conditions at the time of consultation and use of the Platform and expressly declares that he/she accepts them without reservation.</p>
                    <p>These General Conditions may be subject to change. The applicable General Conditions are those in force and accessible on the Company's Platform at the date of access to the Platform by the User.</p>
                    <p>The User acknowledges that he/she has the necessary skills and means to access and use the Platform, and that he/she has verified that the computer configuration used by him/her does not contain any viruses and that it is in good working order.</p>
                    <p>Validations are considered by the User as having the value of an acceptance of the pages browsed, and thus, the User acknowledges having been fully informed of the content of the pages concerned when proceeding to the validation.</p>
                  </div>
                  <div id="policy-5">
                    <Title level={3}>4.Access and availability of the Platform</Title>
                    <p>The Service is accessible online on the Company's Platform.</p>
                    <p>The Company makes its best efforts to make its Service available 24 hours a day and 7 days a week, regardless of the maintenance operations of the Service. In this respect, the Company is bound by an obligation of means.</p>
                    <p>The Company reserves the right to modify, interrupt, at any time, temporarily or permanently all or part of the Service and/or the Platform without prior notice to Users and without right to compensation, and the Company shall not be liable for the consequences of such interruptions or modifications.</p>
                    <p>More generally, the Company reserves the right to make any changes whatsoever to the content of the Platform. The information provided being guaranteed only on the date of update of the Platform.</p>
                  </div>
                  <div id="policy-6">
                    <Title level={3}>5.Access to Services</Title>
                    <p>The Company can assign to OTAs a User Account which allows access to the function AEROHUB.</p>
                    <p>By accessing to AEROHUB, the User can search for available flight tickets and the real-time prices. </p>
                    <p>The User agrees to provide true, accurate and complete information by receiving the User Account. The User agrees to update this information whenever his situation changes. If the User leaves the OTA for which he/she works, he/she is no longer entitled to the use of the User Account. </p>
                    <p>Each User is entirely responsible for the use of his User Account, which he undertakes to keep secret and not to disclose in any form whatsoever to third parties. In the event of loss, theft or any fraudulent use of his identifiers, the User must immediately inform the Company. </p>
                    <p>The Company reserves the right to temporarily suspend a User's access to the Platform and/or Services in the event of loss, theft, or fraudulent use of his/her User Account and/or login details.</p>
                  </div>
                  <div id="policy-7">
                    <Title level={3}>6.User’s guarantee</Title>
                    <p>By accessing the Company's Platform, the User declares, guarantees and agrees to:</p>
                    <div style={{ marginLeft: '20px' }}>
                      <p>‐ Access and use the Platform and Services in good faith, in accordance with the terms of the General Conditions;</p>
                      <p>‐ Not to use any device whose purpose and/or effect is to affect the proper functioning of the Platform and/or the Service it contains;</p>
                      <p>‐ Not to access and/or use the Platform and/or the Service provided by the Company on the said Platform for illicit purposes and/or with the aim of causing damage to the reputation and image of the Company or more generally to infringe the rights, in particular intellectual property rights, of the Company and/or third parties;</p>
                      <p>‐ Not to reproduce or represent all or part of the Platform, in particular images, photos, videos and texts on the Platform, for private purposes beyond the legal exceptions provided for, in particular by intellectual property law, or for direct or indirect marketing, in particular to third parties;</p>
                      <p>‐ Not to engage in practices known as "hacking";</p>
                      <p>‐ Not to attempt to access the Platform's databases for the purpose of using and/or exploiting the electronic and/or postal details of other Platform Users. </p>
                    </div>

                    <p>In the event of a breach of any of these obligations, and without this list being exhaustive, the User acknowledges and accepts that the Company shall have the right to unilaterally and without prior notice, temporarily or permanently deny the User access to all or part of the Platform, without prejudice to any action, claim or compensation that the Company may claim from the User as a result of such breaches. The User will be notified of such a decision by e-mail, to the e-mail address attached to his User Account.</p>
                    <p>In the event of suspension of access to the Platform, the User may be asked to remedy the breach within a reasonable period specified by the Company. At the end of this period, the Company may decide (i) to restore the User's access as soon as possible, or (ii) to terminate the User's access permanently. Any restoration or termination of the User's access will be notified to the User by e-mail, to the e-mail address attached to the User's Account.</p>
                    <p>In case of termination of access to the Site, the User concerned is informed that this will result in the unavailability of his User Account and that he will no longer be able to access the Services offered on the Platform.</p>
                    <p>The User guarantees the Company against any complaint, claim, action and/or demand that it may suffer as a result of any breach by the User of any of its commitments under the terms of these GCU.</p>
                    <p>In such event, User agrees to indemnify Company, to the extent of User's responsibility for the commission of such breach, for any costs (including, without limitation, costs, losses, expenses, legal fees, attorneys' fees and damages) that User may incur.</p>

                  </div>
                  <div id="policy-8">
                    <Title level={3}>7.Company's guarantee</Title>
                    <p>The Company provides the User, through its Platform, with access to a certain number of Services designed to assist him/her in his/her flight research and booking.</p>
                    <p>Although the Company strives to provide reliable content on its Platform, it does not guarantee that its content is free of inaccuracies or omissions and shall not be held liable for any errors or omissions, lack of availability of information and the Service.</p>
                    <p>The User is fully aware that:</p>
                    <div style={{ marginLeft: '20px' }}>
                      <p>- The Company cannot undertake that the Platform will meet his/her particular needs; </p>
                      <p>- The Company cannot guarantee that there is no variation between the flight price indicated at the time of search and the end purchase price;</p>
                      <p>- The Company has no control on the execution and the punctuality of the flight. </p>
                    </div>
                    <p>The prices offered on the Platform can be modified at any time.</p>
                  </div>
                  <div id="policy-9">
                    <Title level={3}>8.Limitation of liability</Title>
                    <p>The User is solely responsible for the use he/she makes of the Platform and the Service he/she accesses from the Platform.</p>
                    <p>The User acknowledges and agrees in this regard that he/she will be personally responsible for any claim or proceeding brought against the Company as a result of his/her non-compliant use of the Service and/or Platform.</p>
                    <p>In any event, the Company shall not be liable for:</p>
                    <div style={{ marginLeft: '20px' }}>
                      <p>- in case of unavailability of the Service for reasons such as the failure of the public electricity network, the failure of the cable telecommunications networks, the loss of connectivity to the Internet network due to public or private operators, in particular the User, whose causes come in particular from strikes, storms, earthquakes or any other cause having the characteristics of force majeure;</p>
                      <p>- within the limits of applicable law, for any consequential damages, including but not limited to loss of profits, data or other intangible property, even if the Company has been advised of the potential for such damages, which may arise (i) from the use or inability to use the Service (ii) as a result of access to the Service by an unauthorized User.</p>
                    </div>
                    <p>The Company shall not be held responsible for any malfunction of any nature whatsoever relating to the User’s computer equipment and Internet access connection when accessing the Platform and more generally the Service.</p>
                    <p>The Company shall not be liable in any way for any proceedings brought against the User who is guilty of non-compliant use of the Platform and/or the Service it provides.</p>
                    <p>The Company shall not be held responsible for any direct or indirect damage that may result from accessing or using the Platform and/or this information, including inaccessibility, loss of data, deterioration, destruction that may affect the User's computer equipment and/or the presence of viruses on the Platform.</p>
                    <p>Any connection or data transmission made using the Identifiers shall be deemed to have been made by the User and under his exclusive responsibility. Consequently, the Company cannot be held responsible for the fraudulent use of the User's Identifiers by a third party.</p>
                    <p>In particular, the Company cannot guarantee the User of the Service on:</p>
                    <div style={{ marginLeft: '20px' }}>
                      <p>- problems with the speed of access to the Platform that the User may encounter;</p>
                      <p>- the availability and the punctuality of the flights provided by airlines.</p>
                    </div>
                    <p>The Company shall not be held responsible for any direct or indirect damage that may result from unavailability of booked flights, change of prices and/or delay of flights. The User shall hold harmless and indemnify AEROHUB of any direct and/or damages due to passengers, and/or any direct and/or indirect losses caused to AEROHUB, including all dispenses and fees incurred.  </p>

                  </div>
                  <div id="policy-10">
                    <Title level={3}>9.Force majeure</Title>
                    <p>The Company shall not be liable if the performance of any of its obligations is prevented or delayed due to a case of force majeure as defined by the laws, including pandemics, natural disasters, fires, malfunction or interruption of the telecommunications network or power grid.</p>
                  </div>
                  <div id="policy-11">
                    <Title level={3}>10.Intellectual Property</Title>
                    <p>The Company is the owner or licensee of the intellectual property rights of both the general structure of the Platform and its content (texts, slogans, graphics, animated or still images, videos, photos, logos, trademarks, sounds, drawings and other content).</p>
                    <p>Any representation, reproduction, modification, distortion and/or exploitation of the Platform and/or its content and/or the Service, in whole or in part, by any process whatsoever and on any medium whatsoever, without the Company's express prior authorization, is prohibited and constitutes copyright infringement.</p>
                    <p>The trademarks, logos, corporate names, acronyms, commercial names, signs and/or domain names of AEROHUB constitute distinctive signs that cannot be used without the express prior authorization of their owner.</p>
                    <p>Any representation and/or reproduction and/or partial or total exploitation of these distinctive signs is therefore prohibited and constitutes a trademark infringement or an act of unfair competition.</p>
                    <p>The User is not allowed to create or exploit derivative works from the Content of the Platform or to attempt to find the source code, sell, assign, or transfer in any way any rights of the Platform.</p>
                  </div>
                  <div id="policy-12">
                    <Title level={3}>11.Personal data</Title>
                    <p>The Company, as data controller or processor, implements personal data processing. </p>
                    <p>The data collected in this way is intended for the relevant departments of the Company and, where applicable, its subcontractors and service providers. The latter are subject to an obligation of confidentiality and may only use your data in accordance with our contractual provisions and the applicable legislation.</p>
                    <p>In accordance with the legislation in force, you have the possibility of exercising your rights to access, rectify, delete, limit the processing of your data, object to it and request its portability. You can also define directives concerning the conservation, deletion and communication of your personal data after your death.</p>
                    <p>These rights can be exercised by e-mail to: sales@aerohubtech.com.</p>
                    <p>You may also file a complaint with the competent data protection authority of your country.</p>
                    <p>The data controller takes all necessary measures to ensure the security and integrity of the data. He puts in place a reporting of incidents having an impact on the data to your benefit within the legal deadline as from his knowledge of the said incident.</p>
                    <p>For any useful purpose, we inform you that the Company reserves the right to archive the personal data that it may have collected in execution of the present contract, for the duration of the limitation period of liability claims or other legal deadlines. In this case, the archived data will be stored on a secure server and may be accessed only in the context of a dispute whose resolution requires the judicial communication of said data.</p>
                    <p>These General Conditions apply concurrently with the Company’s Privacy Policy. The User is invited to regularly review the Company's Privacy Policy.</p>
                  </div>
                  <div id="policy-13">
                    <Title level={3}>12.Hypertext links</Title>
                    <p>The Platform may contain hyperlinks that allow direct access to other content.</p>
                    <p>As the Company is unable to carry out permanent control of these contents, it cannot be held responsible for any reason whatsoever for the contents of these sites and is released from any responsibility in the event that these contents fail to meet legal, regulatory or contractual obligations, or if these sites are affected by viruses or any other parasite of a destructive nature.</p>
                    <p>In no event shall the Company be liable for any operating, financial or commercial loss or any loss of programs or data in the User's information systems or otherwise.</p>
                    <p>Users of the Platform may not set up a hyperlink to it without the prior and express authorization of the Company.</p>
                  </div>
                  <div id="policy-14">
                    <Title level={3}>13.Termination - deletion</Title>
                    <p>At the initiative of the User: Closure of the user account The User may close his User Account at any time. This request can be made at the following address: sales@aerohubtech.com. </p>
                    <p>Deletion or suspension at the initiative of the Company Without prejudice to other cases of suspension provided for in the General Conditions, the Company reserves the right to temporarily or permanently interrupt all or part of the services for reasons related to the security of the service or in the cases provided for in Article 5 or in the event of a proven breach by the User of these General Conditions.</p>
                  </div>
                  <div id="policy-15">
                    <Title level={3}>14.Consequences of deleting or suspending the User Account</Title>
                    <p>The deletion or suspension of the User Account will result in the impossibility to access the Services.</p>
                  </div>
                  <div id="policy-16">
                    <Title level={3}>15.Divisibility</Title>
                    <p>The invalidity of any clause of these General Conditions does not affect the validity of the remaining clauses; they shall continue in the absence of the cancelled clause unless the cancelled clause makes the continuation of the contractual relationship impossible or unbalanced in relation to the original General Conditions.</p>
                  </div>
                  <div id="policy-17">
                    <Title level={3}>16.Claim</Title>
                    <p>Any complaint concerning the use of the Platform’s services should be sent by e-mail to AEROHUB to the email address: sales@aerohubtech.com. Only in case that the complaint does not receive a response under 30 days or is not resolved under 3 months as of the date of receipt of the complaint by AEROHUB, can judicial proceedings be initiated against AEROHUB. </p>
                  </div>
                  <div id="policy-18">
                    <Title level={3}>17.Title of the conditions</Title>
                    <p>The headings at the beginning of each article are for convenience of reading only and may in no case be used as a pretext for any interpretation or distortion of the clauses to which they refer. In case of difficulty of interpretation or contradiction between the content of a clause and its title, the latter is deemed unwritten.</p>
                  </div>
                  <div id="policy-19">
                    <Title level={3}>18.Applicable law and jurisdiction</Title>
                    <p>The present General Conditions are subject to Hong Kong law, expressly excluding all conflict laws. Any dispute arising in connection with these General Conditions will be subject to the competent jurisdiction of Hong Kong.  </p>
                  </div>
                  <div id="policy-20">
                    <Title level={3}>19.Changes/Cancellations by the Suppliers</Title>
                    <p>AEROHUB shall inform you of any changes or cancellations made to an order by the supplier as soon as reasonably possible after AEROHUB becomes aware of such change or cancellation. As an agent, AEROHUB accepts no liability for any changes or cancellations made to any order by the supplier; however we shall use our best efforts to support you in any way to resolve these changes or cancellations. If, as part of any such changed or cancelled order, the supplier offers alternative arrangements or a refund, you must let AEROHUB know whether you wish to accept the alternative arrangements within the timeframe stipulated by the relevant supplier.</p>
                  </div>
                  <div id="policy-21">
                    <Title level={3}>20.Balance currency</Title>
                    <p>AEROHUB may offer the ability to have funds deposited, tracked and settled in a chosen currency, as selected when creating your account (“Balance Currency”), and all offers for travel services will be presented via the AEROHUB platform in the balance currency. AEROHUB manages currency conversion within the AEROHUB platform and we will identify, at the time of the order, the conversion rate that will apply to each order. If an order is cancelled or refunded, the conversion rate that will apply will be the original rate as at the order date. AEROHUB reserves the right to include a transaction fee for currency conversion services to ensure that we limit the financial risk associated with foreign exchange transactions to the greatest extent possible.</p>
                  </div>
                  <div id="policy-22">
                    <Title level={3}>21.Refunds </Title>
                    <p>AEROHUB will only issue refunds to you for voided, changed or cancelled orders if and once the sums have been received by AEROHUB from the supplier. The refund will be automatically added to your payment account.</p>
                  </div>
                  <div id="policy-23">
                    <Title level={3}>22.Non-refundable policy </Title>
                    <p>Refunds are processed in accordance with the Service Provider's terms and conditions. If you are eligible to a refund, your refund will be sent using the same method of payment that you used to make the reservation. Please be aware that even if you are entitled for a refund in accordance with the Service Provider's standards, the amount you receive may not be the exact amount you paid for your booking. This is because flight prices include a variety of components, not all of which may be refundable.</p>
                    <p>All refunds are paid by the relevant Service Provider, and We, as the intermediary, have no control over how quickly they are processed. In our capacity as an intermediary, we shall only handle a payment return if the relevant Service Provider has already sent it to us.</p>
                    <p>The total price of your booking will include the price of the Travel Service(s), any add-on products and our service fees. Please be aware that there are no refunds for our service costs. This is due to the fact that our mediation services, which are paid for using service fees, are only regarded to have been completed after You have received a booking confirmation. Any Service Provider add-on products are refundable only to the extent we have received relevant amounts in return from the Service Provider. Our own services are non-refundable.</p>
                  </div>
                </Col>
              </Row>
            </div >
          </div>
        </div>
      </div >
    );
  }
}
