/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import './less/antMotionStyle.less';
import { Col, Row, Anchor, Typography } from 'antd';
const { Link } = Anchor;
const { Title } = Typography;

export default class privacyPolicy extends React.Component {
  render() {
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        <div className="contact-wrapper" key="Contact_content">
          <div className="contact-content" style={{ maxWidth: '1280px' }}>
            <div className='privacy-policy'>
              <Row>
                <Col span={8}>
                  <Anchor>
                    <Title level={3} style={{ marginTop: '30px', paddingLeft: '16px' }}>PRIVACY POLICY</Title>
                    <Link href="#policy-1" title="Introduction" />
                    <Link href="#policy-2" title="1. What is personal data?" />
                    <Link href="#policy-3" title="2. How we collect your personal information?" />
                    <Link href="#policy-4" title="3. What data is collected from you?" />
                    <Link href="#policy-5" title="4. Why do we collect and process your data?" />
                    <Link href="#policy-6" title="5. How long does AEROHUB keep your data?" />
                    <Link href="#policy-7" title="6. Who are the recipients of your data? " />
                    <Link href="#policy-8" title="7. What are the technical and organizational measures implemented by AEROHUB?" />
                    <Link href="#policy-9" title="8. How to exercise your rights to your data?" />
                    <Link href="#policy-10" style={{}} title="9. Is your Personal Information transferred outside the European Union?" />
                  </Anchor>
                </Col>
                <Col span={16} style={{ fontSize: '17px', lineHeight: '30px', color: '#000', marginBottom: '300px' }}>
                  <div id="policy-1">
                    <Title style={{ marginTop: '30px' }}>AEROHUB PRIVACY POLICY</Title>
                    <p>Last updated on Fri, 30 Mar 2023 00:00:00 GMT</p>
                    <Title level={2}>Introduction</Title>
                    <p>This data protection policy of the aerohubtech.com site takes effect on 30.03.2023.</p>
                    <p>The company that collects personal data on the aerohubtech.com website and implements the data processing is:</p>
                    <p>LETSFLY, Letsfly limited.</p>
                    <p>At AEROHUB, we recognize the importance of protecting your personal information and we are committed to handling it responsibly in accordance with applicable data protection laws. It is essential for AEROHUB to safeguard your personal data and maintain your trust. Our data protection policy describes the general practices that apply to the information and personal data we collect, use or share about our customers, business partners, subcontractors and other organizations with whom AEROHUB has established or is considering establishing a business or contractual relationship</p>
                    <p>The terms contained in the Privacy Policy, unless otherwise provided, have the same meaning as defined in the General Conditions of Service and Use. </p>
                  </div>
                  <div id="policy-2">
                    <Title level={2}>1.What is personal data?</Title>
                    <p>Data is defined as "personal" when it identifies, directly or indirectly, a natural person. For example, a person is directly identified when his or her first and last names appear in a file, a set of data organized in a structured manner. Similarly, an individual may be identifiable when a file contains information that indirectly identifies him or her (e.g., registration number, telephone number, photograph, etc.).</p>
                    <p>This information must be processed and stored in accordance with the legal and regulatory provisions on the protection of personal data.</p>
                  </div>
                  <div id="policy-3">
                    <Title level={2}>2.How we collect your personal information </Title>
                    <p>On our website, personal data is collected:</p>
                    <ul style={{ paddingLeft: '30px' }}>
                      <li>Through the contact form, and later when updating the information upon request of the person concerned;</li>
                      <li>Through the information entered during the creation of the online account</li>
                      <li>Through the information entered when subscribing to our newsletter</li>
                      <li>Through exchanges with our assistance in using the site</li>
                      <li>Through direct communication and purchasing orders. </li>
                    </ul>
                  </div>
                  <div id="policy-4">
                    <Title level={2}>3.What data is collected from you?</Title>
                    <p>For users of our website AEROHUB :</p>
                    <p>The following personal information may be collected:</p>
                    <ul style={{ paddingLeft: '30px' }}>
                      <li>Last name, first name,</li>
                      <li>Email address</li>
                      <li>Phone number</li>
                      <li>Country</li>
                      <li>Company name</li>
                      <li>Message content.</li>
                    </ul>
                  </div>
                  <div id="policy-5">
                    <Title level={2}>4.Why do we collect and process your data?</Title>
                    <p>We collect your personal data for the following purposes:</p>
                    <p><b>1) Purpose 1: Provision and management of the online user account and site features</b></p>
                    <p>The legal basis for this processing is the consent of the website user when the account is created and the execution of the contract binding us under our General Conditions of Service and Use of our website.</p>
                    <p><b>2) Purpose 2: Management and follow-up of information requests and exchanges with users</b></p>
                    <p>The legal basis for this processing is the pursuit of AEROHUB's legitimate interests in responding to requests from users of the site, to enable them to benefit from the features offered on the site, to improve AEROHUB's service and, more generally, to manage and monitor their relationship.</p>
                    <p><b>3) Purpose 3: Sending newsletters or email alerts to customers or prospects</b></p>
                    <p>The legal basis for this processing is the subscriber's consent, it being specified that the subscriber has the possibility of withdrawing his consent at any time, and without having to provide any reason or explanation.</p>
                    <p><b>4) Purpose 4: Commercial prospecting by telephone or e-mail </b></p>
                    <p>The legal basis for this processing is the legitimate interests of AEROHUB, it being specified that the recipient of such canvassing has the right to object at any time without having to provide a reason or explanation.</p>
                    <p>Commercial prospecting may concern other services offered by AEROHUB concerning the apartment purchased in the future.</p>
                    <p><b>5) Purpose 5: Management of the relationship with partners or subcontractors</b></p>
                    <p>The legal basis for this processing is the legitimate interest of AEROHUB or the monitoring of the proper execution of contracts concluded with partners.</p>
                    <p>Finally, with regard to the management of invoices and the keeping of the associated accounts, such Processing may result from AEROHUB's legal obligations.</p>
                    <p><b>6) Purpose 6: Management of requests to exercise the rights of data subjects</b></p>
                    <p>The legal basis for this processing is the fulfilment of legal and regulatory obligations.</p>
                    <p><b>7) Purpose 7: Management and follow-up of pre-litigation and litigation</b></p>
                    <p>The legal basis for this processing is the legitimate interest of AEROHUB.</p>
                  </div>
                  <div id="policy-6">
                    <Title level={2}>5.How long does AEROHUB keep your data?</Title>
                    <p>Your data is processed by AEROHUB for the time necessary to carry out the above-mentioned treatments. </p>
                    <p>1) In general, AEROHUB keeps the data of its clients or prospects for the duration of the business relationship for which they have expressed the intention to purchase by communicating their details. </p>
                    <p>2) When the User has submitted his/her personal information via the contact form, his data is kept generally during all the duration of the business relationship or communication. If the User has not made a purchase, the personal data related to this account is kept for three years from the last connection in active base and then two years in intermediate base.</p>
                    <p>3) After the completion of the purchase, customer data may be kept in an active database for a period of five years for the purpose of providing additional services, or for the commercial prospecting of additional services. </p>
                    <p>4) AEROHUB keeps the data of prospects who have not made a purchase in an active database for a period of three years after the end of commercial prospecting and then two years in an intermediate database.</p>
                    <p>5) At the end of the above-mentioned retention periods in the active database, your personal data may be retained in the form of archives, to meet any legal and/or regulatory obligations imposed on AEROHUB. By way of illustration, your personal data is kept in archive form for ten years as regards the keeping of accounting documents and proofs or for the legal prescription periods (cf. in general, five years as regards the prescription period under common law in civil matters, six years as regards the prescription period under common law in criminal matters or two to five years as regards labour law).</p>
                    <p>6) However, your personal data may be kept for longer than the above-mentioned periods after obtaining your consent, if necessary.</p>
                    <p>After the data has been stored in the active database and then in the intermediate database, the data will be deleted. </p>
                  </div>
                  <div id="policy-7">
                    <Title level={2}>6.Who are the recipients of your data? </Title>
                    <p>As a matter of principle, only authorized AEROHUB personnel have access to your personal data, in particular employees, trainees or temporary workers in charge of marketing and communication, sales, customer and prospect relations, administration, finance and legal, business development, IT, etc.</p>
                    <p>In the course of our business, we do not share your data with third parties except in the following circumstances where AEROHUB uses the following categories of partners:</p>
                    <ul style={{ paddingLeft: '30px' }}>
                      <li>Services or entities in charge of control, for example auditors, services or entities in charge of internal or external control procedures, organizations authorized to carry out audits;</li>
                      <li>AEROHUB's advisers (legal, financial, accounting, etc.);</li>
                      <li>Technical providers and suppliers, domain name host, companies in charge of marketing, any other service provider of AEROHUB;</li>
                      <li>AEROHUB's business partners, including sales service providers;</li>
                      <li>The social and fiscal bodies entitled to receive, in certain cases, personal data;</li>
                      <li>Organizations, legal auxiliaries and ministerial officers, in particular in the context of their debt collection mission;</li>
                      <li>If applicable, the organization in charge of managing the Do Not Call List;</li>
                      <li>The auxiliary officers of justice, the ministerial officers and, if necessary, the competent jurisdictions or administrative or judicial authorities.</li>
                    </ul>
                    <p>We may also be required to disclose your personal data in the event of legitimate requests from public or authorized authorities, on the basis of legal or regulatory provisions applicable to us.</p>
                    <p>The data of the users of the aerohubtech.com website can be shared with other websites owned by the LETSFLY company in the future, as long as the data is kept.  </p>
                    <p>Under no circumstances will AEROHUB sell your personal data to another company. </p>
                  </div>
                  <div id="policy-8">
                    <Title level={2}>7.What are the technical and organizational measures implemented by AEROHUB? </Title>
                    <p>AEROHUB implements the appropriate technical and organizational measures necessary to ensure that the processing complies with the applicable regulations on the protection of personal data. </p>
                    <p>In addition, AEROHUB takes every precaution to preserve the security of your data. To this end, AEROHUB has put in place all the necessary measures to secure its computer systems or has ensured that its subcontractors take the necessary precautions to protect and secure your data. </p>
                    <p>Your personal data is stored on secure servers, accessible to a limited number of people with specific access rights to these systems. </p>
                  </div>
                  <div id="policy-9">
                    <Title level={2}>8.How to exercise your rights to your data?</Title>
                    <p>For processing requiring consent, the data subject may withdraw consent by making a request to LETSFLY (Flat/RM 27, 10/F Cheung Tak Industrial Building, NO. 30 Wong Chuk Hang Road, Wong Chuk Hang, Hong Kong) or by email: sales@aerohubtech.com.</p>
                    <p>People visiting our site AEROHUB have :</p>

                    <div style={{ marginLeft: '20px' }}>
                      <p><b>• A right of access: </b></p>
                      <p>The right for any person to obtain communication of all information concerning him or her held by AEROHUB.</p>
                      <p><b>• A right of rectification:  </b></p>
                      <p>The right for any person to obtain the rectification of inaccurate information about him or her held by AEROHUB.</p>
                      <p><b>• A right to object:  </b></p>
                      <p>The right to object can be exercised either at the time the information is collected or later, by contacting the person responsible for the file. There are two possibilities:</p>
                      <p style={{ marginLeft: '10px' }}>⚬ The right for any person to object, on legitimate grounds, to being included in a file.</p>
                      <p style={{ marginLeft: '10px' }}>⚬ As regards commercial prospecting, any person may object to his or her data being distributed, transmitted or stored. This right can be exercised without having to justify a legitimate reason.</p>
                      <p><b>• A right to erasure: </b></p>
                      <p>The right for any person to obtain the deletion of personal data concerning him.</p>
                      <p><b>• A right to set out guidelines for what happens to my personal data after my death:  </b></p>
                      <p>In the absence of a directive or a statement to the contrary in the directive, the heirs of the individual may exercise the deceased's personal data rights after his or her death.</p>
                      <p><b>• A right to limit processing: </b></p>
                      <p>It is the right to have a processing operation suspended until a verification can take place (to verify the accuracy of the personal data, to check whether the legitimate reasons given by the person prevail over those of the data controller in the case of a request for opposition, etc.).</p>
                      <p><b>• A right to portability:  </b></p>
                      <p>The individual has the right to have his or her personal data transmitted in a structured format</p>
                      <p>⚬ To herself; or</p>
                      <p>⚬ Directly from one controller to another, where technically possible.</p>
                      <p><b>• A right to withdraw their consent:   </b></p>
                      <p>The individual has the right to withdraw his or her consent at any time where processing is based on that consent.</p>
                      <p><b>• A right to lodge a complaint with a supervisory authority. </b></p>
                      <p>You can exercise your rights by writing to: LETSFLY (Flat/RM 27, 10/F Cheung Tak Industrial Building, NO. 30 Wong Chuk Hang Road, Wong Chuk Hang, Hong Kong) or by email : sales@aerohubtech.com. </p>
                      <p>Specifically:</p>
                      <p style={{ marginLeft: '10px' }}>• To stop receiving emails or other notifications:</p>
                      <p style={{ marginLeft: '30px' }}>⚬ At the bottom of each email sent, there is a statement summarizing your rights and the possibility to unsubscribe by clicking on a link.</p>
                      <p style={{ marginLeft: '10px' }}>• To access, rectify or exercise your rights to portability of your data stored by AEROHUB: </p>
                      <p style={{ marginLeft: '30px' }}>⚬ You can make a request for access or portability of your data by email or by post. This request requires a copy of an identity document, without which it is not possible to disclose or modify your data.</p>
                      <p style={{ marginLeft: '30px' }}>⚬ You can exercise your rights of access, rectification or portability by writing to: LETSFLY (Flat/RM 27, 10/F Cheung Tak Industrial Building, NO. 30 Wong Chuk Hang Road, Wong Chuk Hang, Hong Kong) or by email : sales@aerohubtech.com. </p>
                      <p style={{ marginLeft: '30px' }}>⚬ A reply will be sent to you within 1 month of receiving the request.</p>
                      <p style={{ marginLeft: '10px' }}>• To object to the transfer of your data, to object to being included in a file or to exercise your right of limitation:  </p>
                      <p style={{ marginLeft: '30px' }}>⚬ You may exercise your rights, provided you have a legitimate reason for doing so, by writing with a copy of a proof of identity to : LETSFLY (Flat/RM 27, 10/F Cheung Tak Industrial Building, NO. 30 Wong Chuk Hang Road, Wong Chuk Hang, Hong Kong) or by email : sales@aerohubtech.com.  </p>
                      <p style={{ marginLeft: '30px' }}>A reply will be sent to you within one month of receipt of the request.</p>
                      <p style={{ marginLeft: '10px' }}>• To give my instructions regarding my personal data after my death: </p>
                      <p style={{ marginLeft: '30px' }}>⚬ You can exercise your right to give instructions concerning your data after your death by writing with a copy of a proof of identity to : LETSFLY (Flat/RM 27, 10/F Cheung Tak Industrial Building, NO. 30 Wong Chuk Hang Road, Wong Chuk Hang, Hong Kong) or by email : sales@aerohubtech.com.</p>
                      <p style={{ marginLeft: '30px' }}>A reply will be sent to you within 1 month of receiving the request.</p>
                    </div>

                  </div>
                  <div id="policy-10">
                    <Title level={2}>9.Is your Personal Information transferred outside the European Union?</Title>
                    <p>The site is hosted by Alibaba Cloud Computing Co., Ltd., located at Building 1, Aliyun Feitian Park, Yunqi Town, Xihu District, Hangzhou City, Zhejiang Province, China. The transfer of data is governed by the outsourcing contract and additional technical and organizational measures to ensure an equivalent level of protection.</p>
                  </div>
                </Col>
              </Row>
            </div >
          </div>
        </div>
      </div>
    );
  }
}
